<script setup>
import { ref, onMounted } from "vue";
import Http from './http';
import {$vfm, VueFinalModal, ModalsContainer} from 'vue-final-modal'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

let email = ref('')
let comment = ref('')
let accept = ref(false)
let showModal = ref(false)
let legaltitle = ref('')
let legaldata = ref('')
let formIn = ref(true)

const schema = yup.object({
  email: yup.string().required('por favor introduca su email').email('por favor introduca un email válido').label('Email'),
  comment: yup.string().required('por favor introduca su comentario').label('Comentario'),
});

onMounted(() => {
  getlegal()
});

async function onSubmitb(values) {
  try {
    const resp = await Http.post('/newmessage',  values  )
    await console.log(resp.data)
    await checkresponse(resp.data)
  } catch (error) {
    console.log(Object.keys(error), error.message);
  }

}
async function getlegal() {
  try {
    const resp = await Http.get('/getlegal' )
    await setlegal(resp.data)
  } catch (error) {
    console.log(Object.keys(error), error.message);
  }
}
function setlegal(data) {
  legaltitle.value = data.title
  legaldata.value = data.content
}
function checkresponse(data) {
  if (data.success == 'success') {
    formIn.value = false
  }
}

</script>

<template>
  <div v-if="formIn==true">
    <Form :validation-schema="schema" @submit="onSubmitb">
      <Field v-model="email" class="form-control mb-3" id="email" placeholder="Email:*" name="email"/>
      <ErrorMessage name="email" class="errorfom"/>
      <Field v-model="comment" class="form-control mb-2" id="comment" placeholder="Comentario:*" name="comment"/>
      <ErrorMessage name="comment" class="errorfom"/>
      <input v-model="accept" type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1"> &nbsp;Acepto la <span @click="showModal = true"
                                                                                 class="link-secondary">Política de privacidad</span>
      </label>
      <button type="submit" :disabled="accept === false" class="btn btn-sm btn-secondary mt-3">Enviar mensaje</button>

    </Form>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <div class="d-flex justify-content-end">
        <button @click="showModal = false" type="button" class="btn-close pull-right"></button>
      </div>
      <span class="modal__title mb-3">{{ legaltitle }}</span>
      <div class="modal__content">
        <p v-html="legaldata"></p>
      </div>
    </vue-final-modal>
  </div>
  <div v-else>
    <p>El formulario de contacto ha sido enviado.</p>
    <h3>Gracias por contactar</h3>
    <p>Pronto responderemos a su consulta</p>
  </div>
</template>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  max-height: 550px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 260px;

}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 720px;
  max-height: 450px;
  overflow-y: scroll;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;

}
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.modal__content{
  color: #333;
}

</style>

